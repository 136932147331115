@import "https://cdn.jsdelivr.net/npm/ol/ol.css"; /* Importing default styling for OpenLayers */

* {
    font-family: 'Montserrat';
}

html, body {
    margin: 0;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

main {
    position: relative;
    flex: 1;
    min-height: 0;
}

/* Navbar styling, includes logos, nav links, title, and container */
.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: #141414;
    border-radius: 0 0 2em 2em;
    position: relative;
    flex-wrap: nowrap;
}

.imos-img {
    padding-left: 3em;
    padding-right: 5em;
}

.imas-img {
    padding-left: 5em;
    padding-right: 3em;
}

.imas-img img, .imos-img img{
    max-width: 200px;
    height: auto;
}

.title-and-links {
    text-align: center;
    justify-content: center;
}

.title-and-links h1 {
    margin: 0;
    font-size: 4rem;
    color: white;
}

.nav-menu {
    text-align: center;
}

.nav-links {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    list-style-type: none;
    padding: 0;
}

.nav-links a {
    display: inline-block;
    position: relative;
    color: white;
    text-decoration: none;
    font-size: 1.3rem;
    padding: 10px 20px 5px; 
    transition: background-color 0.3s, color 0.3s;
}

.nav-links a.active{
    color: white;
    font-weight: bold;
}

/* nav links animation */
.nav-links a:hover{
    color: white;
    font-weight: bolder;
}

.nav-links a::before{
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform-origin: bottom middle;
    transition: 0.4s ease-out;
}

.nav-links a:hover::before{
    color: white;
    transform: scaleX(1);
    transform-origin: bottom middle;
}

/* OpenLayers map positioning */
#map {
    position: relative;
    width: 100%;
    height: 103%;
    top: -2em;
}

/* Left and Right side container styling */
.options-container {
    display: flex;
    justify-content: center;

    position: absolute;
    z-index: 5;
    top: 50%;
    transform: translateY(-50%);
    width: 400px;
    height: 70%;
    max-height: 715px;
    padding: 1em;
    color: white;

    transition: width 0.3s ease-in-out;
    overflow-y: auto;
    overflow-x: hidden;

    /* Background transparency and blur */
    background: rgba(0, 0, 0, 0.52);
    border-radius: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(9px);
}

.options-container.hidden {
    overflow: hidden;
}

.options-container::-webkit-scrollbar {
    width: 1em;
    background-color: #f1f1f1;
    border-radius: 16px;
}

.options-container::-webkit-scrollbar-track {
    background-color: #d3d3d3;
    border-radius: 10px;
    margin-left: -20px;
}

.options-container::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 10px;
    border: 1px solid #d3d3d3;
}

.options-container::-webkit-scrollbar-thumb:hover {
    background-color: #666;
}

.content {
    padding: 0 10px;
}

.vertical-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
    font-size: 2rem;
    font-weight: bold;
    color: white;
    opacity: 0.8;
    display: flex;
    flex-direction: column;
    align-items: center;
    writing-mode: vertical-lr;
    text-orientation: upright;
}

.vertical-text {
    display: none;
}

.options-container.hidden .vertical-text {
    display: flex;
}

.toggle-button {
    position: absolute;
    width: 1em;
    z-index: 10;
    background-color: #383838;
    color: white;
    font-size: 1rem;
    padding: 6em 0.6em;
    cursor: pointer;
    border-radius: 0.5em;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
}

#filter-toggle {
    right: 0;
    top: 50%;
    margin-left: auto;
    transform: translateY(-50%);
    transition: right 0.3s ease-in-out;
}

#track-toggle {
    left: 0;
    top: 50%;
    margin-right: auto;
    transform: translateY(-50%);
    transition: left 0.3s ease-in-out;
}

.options-container .content {
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
}

.options-container.hidden {
    width: 115px;
}

.options-container.hidden .content {
    opacity: 0;
}

/* Temperature key styling for the filter container (Left side) */
#temperature-key-container {
    padding: 10px;
    padding-bottom: 50px;
    padding-top: 0px;
    position: relative;
    width: 300px;
    margin: auto;
    text-align: center;
}

#temperature-title {
    position: relative;
    margin-bottom: 5px;
    text-align: center;
}

#temperature-key {
    background-image: linear-gradient(to right, rgb(0,0,255), rgb(0, 225, 255), rgb(255,255,255), rgb(255, 230, 0), rgb(255,150,0));
    width: 100%;
    height: 10px;
    border-radius: 10px;
    position: relative;
}

.temperature-point {
    position: absolute;
    top: 10px;
    width: 1px;
    height: 10px;
    background-color: white;
}

.temperature-label {
    position: absolute;
    top: 20px;
    transform: translateX(-50%);
    text-align: center;
}

/* Filter container styling (Left side) */
#filter-options {
    left: 2em;
    direction: rtl;
    max-width: 350px;
    transition: width 0.3s ease-in-out;
}

#filter-options * {
    direction: ltr;
}

#filter-text {
    left: 1em;
}

#filter-form label {
    margin-bottom: 0;
    padding-top: 10px;
}

#filter-form input[type="text"] {
    padding: 5px;
    border: 2px solid #303030;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s ease-in-out;
}

#filter-form button:hover, input:hover, select:hover {
    background-color: #e7e7e7;
}

#filter-form select option {
    background-color: white;
}

#filter-form select {
    padding: 5px;
    border: 2px solid #303030;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s ease-in-out;
}

/* Graph container styling (Right side) */
#track-options {
    right: 2em;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

#track-options h4 {
    margin: 0;
    text-align: center;
}

#track-text {
    right: 1em;
}

/* Styling for popup when clicking on a dive point */
.popover {
    width: auto !important;
    max-width: none !important;
    word-break: normal;
    transform: translateZ(0);
}

.popover-body {
    white-space: nowrap;
    padding: 10px !important;
}

.custom-popover {
    z-index: 1000 !important;
}

/* OpenStreetsMap Circle anomaly styling fix (wierd circle produced at center of map from OSM)*/
.circle-overlay {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #f2efe9;
    border: 2px solid #f2efe9;
    position: absolute;
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 1 !important;
}

/* Map controls primarily for laptop users  */
.controls-container {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(31, 31, 31);
    border-radius: 50px;
    padding: 10px;
    z-index: 1000;
}

.control-button {
    background-color: #2e2e2e;
    color: white;
    border: none;
    font-size: 1.4em;
    padding: 10px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reset-button {
    font-size: 1.3em;
}

.control-button:hover {
    background-color: #414141;
}

/* OpenLayers distance scale (bottom left) */
.distance-scale {
    position: absolute;
    bottom: 20px;
    left: 10px;
    z-index: 1000;
}

/* Range slider filter for months of the year */
.date-slider-container {
    width: 60%;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    z-index: 1000;
}

input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 97%;
    position: absolute;
    pointer-events: none;
}

input[type="range"]::-webkit-slider-runnable-track {
    background: #ddd;
    height: 4px;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 6px;
    height: 20px;
    background: #333;
    pointer-events: all;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    position: relative;
}

#month-labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 50px;
    padding: 0 5px;
}

#month-labels span {
    font-size: 12px;
}

#month-labels span:first-child {
    text-align: left;
}

#month-labels span:last-child {
    text-align: right;
}

/* Loading GIF positioning */
#loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}

/* Message for when no data is retrieved from database */
#no-data {
    position: absolute;
    width: auto;
    height: auto;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9998;
    display: flex;
    justify-content: center;
    align-items: center;

    background: rgba(0, 0, 0, 0.52);
    border-radius: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(9px);
}

#no-data h4 {
    color: white;
    margin: 10px;
}

/* Media Queries for browser and screen-size responsiveness */
@media (max-width: 1440px) {
    .imos-img img, .imas-img img {
        max-width: 175px;
    }

    .title-and-links h1 {
        font-size: 2.5rem;
    }

    .nav-links a {
        font-size: 1.15rem;
    }
}
@media (max-width: 1100px){
    .imos-img img, .imas-img img {
        max-width: 150px;
    }

    .title-and-links h1 {
        font-size: 2rem;
    }

    .nav-links a {
        font-size: 1rem;
    }

    .year-selector {
        font-size: 1rem;
        padding: 5px 8px;
    }

    .options-container {
        width: 275px;
    }

    .options-container h1, .options-container p{
        font-size: 0.9rem;
    }

    #temperature-key-container {
        width: 100%;
    }

    .options-container canvas {
        transform: scale(0.65);
    }
}

@media (max-width: 768px) {
    .imos-img img, .imas-img img {
        max-width: 80px;
    }

    .title-and-links h1 {
        font-size: 1.7rem;
    }

    .nav-links a {
        font-size: 0.7rem;
    }
}

@media (max-height: 880px) {
    .options-container.hidden .vertical-text {
        font-size: 1.5rem;
    }

    #filter-text {
        left: 1.6em;
    }

    #track-text {
        right: 1.6em;
    }
}