@import "https://cdn.jsdelivr.net/npm/ol/ol.css";
* {
  font-family: Montserrat;
}

html, body {
  flex-direction: column;
  height: 100%;
  margin: 0;
  display: flex;
  overflow: hidden;
}

main {
  flex: 1;
  min-height: 0;
  position: relative;
}

.navbar {
  z-index: 10;
  background-color: #141414;
  border-radius: 0 0 2em 2em;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.imos-img {
  padding-left: 3em;
  padding-right: 5em;
}

.imas-img {
  padding-left: 5em;
  padding-right: 3em;
}

.imas-img img, .imos-img img {
  max-width: 200px;
  height: auto;
}

.title-and-links {
  text-align: center;
  justify-content: center;
}

.title-and-links h1 {
  color: #fff;
  margin: 0;
  font-size: 4rem;
}

.nav-menu {
  text-align: center;
}

.nav-links {
  justify-content: space-evenly;
  align-items: center;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.nav-links a {
  color: #fff;
  padding: 10px 20px 5px;
  font-size: 1.3rem;
  text-decoration: none;
  transition: background-color .3s, color .3s;
  display: inline-block;
  position: relative;
}

.nav-links a.active {
  color: #fff;
  font-weight: bold;
}

.nav-links a:hover {
  color: #fff;
  font-weight: bolder;
}

.nav-links a:before {
  content: "";
  transform-origin: bottom middle;
  background-color: #fff;
  width: 100%;
  height: 1px;
  transition: all .4s ease-out;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
}

.nav-links a:hover:before {
  color: #fff;
  transform-origin: bottom middle;
  transform: scaleX(1);
}

#map {
  width: 100%;
  height: 103%;
  position: relative;
  top: -2em;
}

.options-container {
  z-index: 5;
  color: #fff;
  backdrop-filter: blur(9px);
  background: #00000085;
  border-radius: 16px;
  justify-content: center;
  width: 400px;
  height: 70%;
  max-height: 715px;
  padding: 1em;
  transition: width .3s ease-in-out;
  display: flex;
  position: absolute;
  top: 50%;
  overflow: hidden auto;
  transform: translateY(-50%);
  box-shadow: 0 4px 10px #00000080;
}

.options-container.hidden {
  overflow: hidden;
}

.options-container::-webkit-scrollbar {
  background-color: #f1f1f1;
  border-radius: 16px;
  width: 1em;
}

.options-container::-webkit-scrollbar-track {
  background-color: #d3d3d3;
  border-radius: 10px;
  margin-left: -20px;
}

.options-container::-webkit-scrollbar-thumb {
  background-color: #555;
  border: 1px solid #d3d3d3;
  border-radius: 10px;
}

.options-container::-webkit-scrollbar-thumb:hover {
  background-color: #666;
}

.content {
  padding: 0 10px;
}

.vertical-text {
  white-space: nowrap;
  color: #fff;
  opacity: .8;
  writing-mode: vertical-lr;
  text-orientation: upright;
  flex-direction: column;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.options-container.hidden .vertical-text {
  display: flex;
}

.toggle-button {
  z-index: 10;
  color: #fff;
  cursor: pointer;
  opacity: .8;
  background-color: #383838;
  border-radius: .5em;
  justify-content: center;
  align-items: center;
  width: 1em;
  padding: 6em .6em;
  font-size: 1rem;
  display: flex;
  position: absolute;
}

#filter-toggle {
  margin-left: auto;
  transition: right .3s ease-in-out;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

#track-toggle {
  margin-right: auto;
  transition: left .3s ease-in-out;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.options-container .content {
  opacity: 1;
  transition: opacity .3s ease-in-out;
}

.options-container.hidden {
  width: 115px;
}

.options-container.hidden .content {
  opacity: 0;
}

#temperature-key-container {
  text-align: center;
  width: 300px;
  margin: auto;
  padding: 0 10px 50px;
  position: relative;
}

#temperature-title {
  text-align: center;
  margin-bottom: 5px;
  position: relative;
}

#temperature-key {
  background-image: linear-gradient(to right, #00f, #00e1ff, #fff, #ffe600, #ff9600);
  border-radius: 10px;
  width: 100%;
  height: 10px;
  position: relative;
}

.temperature-point {
  background-color: #fff;
  width: 1px;
  height: 10px;
  position: absolute;
  top: 10px;
}

.temperature-label {
  text-align: center;
  position: absolute;
  top: 20px;
  transform: translateX(-50%);
}

#filter-options {
  direction: rtl;
  max-width: 350px;
  transition: width .3s ease-in-out;
  left: 2em;
}

#filter-options * {
  direction: ltr;
}

#filter-text {
  left: 1em;
}

#filter-form label {
  margin-bottom: 0;
  padding-top: 10px;
}

#filter-form input[type="text"] {
  border: 2px solid #303030;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  transition: border-color .3s ease-in-out;
}

#filter-form button:hover, input:hover, select:hover {
  background-color: #e7e7e7;
}

#filter-form select option {
  background-color: #fff;
}

#filter-form select {
  border: 2px solid #303030;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  transition: border-color .3s ease-in-out;
}

#track-options {
  color: #fff;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
  right: 2em;
}

#track-options h4 {
  text-align: center;
  margin: 0;
}

#track-text {
  right: 1em;
}

.popover {
  word-break: normal;
  transform: translateZ(0);
  width: auto !important;
  max-width: none !important;
}

.popover-body {
  white-space: nowrap;
  padding: 10px !important;
}

.custom-popover {
  z-index: 1000 !important;
}

.circle-overlay {
  pointer-events: none;
  background-color: #f2efe9;
  border: 2px solid #f2efe9;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 1 !important;
}

.controls-container {
  z-index: 1000;
  background-color: #1f1f1f;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.control-button {
  color: #fff;
  cursor: pointer;
  background-color: #2e2e2e;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 0 5px;
  padding: 10px;
  font-size: 1.4em;
  display: flex;
}

.reset-button {
  font-size: 1.3em;
}

.control-button:hover {
  background-color: #414141;
}

.distance-scale {
  z-index: 1000;
  position: absolute;
  bottom: 20px;
  left: 10px;
}

.date-slider-container {
  z-index: 1000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  padding: 10px;
  display: flex;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

input[type="range"] {
  appearance: none;
  pointer-events: none;
  width: 97%;
  position: absolute;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: #ddd;
  height: 4px;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  pointer-events: all;
  z-index: 10;
  background: #333;
  width: 6px;
  height: 20px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#month-labels {
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
  padding: 0 5px;
  display: flex;
}

#month-labels span {
  font-size: 12px;
}

#month-labels span:first-child {
  text-align: left;
}

#month-labels span:last-child {
  text-align: right;
}

#loading {
  z-index: 9999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#no-data {
  z-index: 9998;
  backdrop-filter: blur(9px);
  background: #00000085;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  display: flex;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 10px #00000080;
}

#no-data h4 {
  color: #fff;
  margin: 10px;
}

@media (width <= 1440px) {
  .imos-img img, .imas-img img {
    max-width: 175px;
  }

  .title-and-links h1 {
    font-size: 2.5rem;
  }

  .nav-links a {
    font-size: 1.15rem;
  }
}

@media (width <= 1100px) {
  .imos-img img, .imas-img img {
    max-width: 150px;
  }

  .title-and-links h1 {
    font-size: 2rem;
  }

  .nav-links a {
    font-size: 1rem;
  }

  .year-selector {
    padding: 5px 8px;
    font-size: 1rem;
  }

  .options-container {
    width: 275px;
  }

  .options-container h1, .options-container p {
    font-size: .9rem;
  }

  #temperature-key-container {
    width: 100%;
  }

  .options-container canvas {
    transform: scale(.65);
  }
}

@media (width <= 768px) {
  .imos-img img, .imas-img img {
    max-width: 80px;
  }

  .title-and-links h1 {
    font-size: 1.7rem;
  }

  .nav-links a {
    font-size: .7rem;
  }
}

@media (height <= 880px) {
  .options-container.hidden .vertical-text {
    font-size: 1.5rem;
  }

  #filter-text {
    left: 1.6em;
  }

  #track-text {
    right: 1.6em;
  }
}
/*# sourceMappingURL=index.55366f5c.css.map */
